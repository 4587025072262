/* eslint-disable array-callback-return */
/*****components***/
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import { withScriptjs } from "react-google-maps";
import LoginG from "../login/loginG";

//import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from "react-google-login";
/*custom components*/
import PageLoading from "../../components/PageLoading";
import Map from "../../components/Map";
import CartInfo from "../../components/CartInfo";
import Form from "../../components/Form";

import SuccesfulMessage from "../../components/SuccesfulMessage";

import paymentsTitleIcon from "../../assets/images/paymentsTitleIcon.png";

import Login from "../../components/Login";

import {
  toast,
  MDBAlert,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBBtn,
} from "mdbreact";
import {
  find,
  getShopCart,
  calculateDiscount,
  changeHandler,
  loginSN,
  setGTMEvent,
  calculateSkusDiscount,
  findObjName,
  convertNumber,
  getCookie,
  setCookie,
  showMessage,
  showError,
  getCurrentStore,
  getLanguage,
  getRenderObj,
  getImagePath,
  findValueById,
  getOptions,
  mergeFormData,
  getStoresInfo,
  login,
  getShippingMethodsForm,
  getPersonalDataForm,
  clearformData,
  preloadForm,
  parseFormData,
  getLoginInfo,
  validateForm,
  toFixed,
  getDefValues,
  getCurrency,
  updateCart,
  callApi,
} from "../../common/functions";

import { v4 as uuid } from "uuid";

/*functions*/

const foldername = process.env.REACT_APP_FOLDER_LAYOUT;

//const AnyReactComponent = ({ text }) => <div style={{"position":"absolute", "color":"red" }}>{text}</div>;
const GoogleMapsAPI = "AIzaSyD9QlvrLNSxDZWXffh5kSBDR7OjY1FSjr8";

/*
function getTimeRemaining(endtime) {
  const total = Date.parse(endtime) - Date.parse(new Date());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));
  
  return {
	total,
	days,
	hours,
	minutes,
	seconds
  };
}
*/
class page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tryout: 0,
      products: [],
      paymentMethod: "",
      latitude: -16.5337955,
      longitude: -68.0865515,
      btncolor: "primary",
      validcouponcode: [],
      checkEventTerms: true,
      lockCouponButton: false,
      needReview: false,
      needShow: false,
      discountShipping: 0,
      discountSubtotal: 0,
      discountSHMessages: [],
      discountSTMessages: [],
      couponcode: "",
      dedicationName: "",
      dedicationMessage: "222",
      dedicationInfo: {
        name: "",
        message: "",
      },
      combos: [],
      MetaTags: {
        title: getDefValues().setOrderLabel,
        names: [
          { name: "description", content: getDefValues().setOrderLabel },
          { name: "keywords", content: process.env.REACT_APP_ORG_NAME },
          { name: "robots", content: "INDEX,FOLLOW" },
        ],
      },
      clatitude: 0,
      clongitude: 0,
      addressForm: {},
      isLoading: true,
      shippingLabel: "",
      city: "",
      totalProducts: 0,
      baseShippingCost: 0,
      cartLabel: getDefValues().cartLabel.toUpperCase(),
      cartLabelDesc: getDefValues().cartLabelDesc,
      subtotal: 0,
      reloadPage: false,
      formActivePanel: 1,
      userDataInfo: {},
      showDiscount: false,
      showPersonalData: true,
      showShippingData: false,
      shippingCost: 0,
      coords: [],
      isValidPD: false,
      finalFormData: {},
      isValidSD: false,
      shippingFormId: 0,
      formData: {
        email: {
          label: getDefValues().emailLabel,
          value: "",
          type: "INPUT",
          validationtype: "email",
        },
        password: {
          label: getDefValues().passwordLabel,
          value: "",
          type: "PASSWORD",
          validationtype: "PASSWORD",
        },
      },
      formDataI: {
        invoicenumber: {
          label: "",
          value: "",
          type: "INPUT",
          validationtype: "NUMBER",
        },
        invoicename: {
          label: "",
          value: "",
          type: "INPUT",
          validationtype: "TEXT",
        },
      },
      activeFormSD: null,
      formDataPD: {},
      formConfigPD: {},
      formDataSD: {},
      modal1: false,
      windowHeight: window.innerHeight,
      modal2: false,
      modal3: false,
      modal4: false,
      showMap: false,
      showlogin: true,
      address: "",
      showrecovery: false,
      showforgot: false,
      lockformPD: false,
      haveDirection: false,
      userId: -1,
      shippingOptionId: "0",
      discount: 0,
      statusid: 0,
      orderId: 0,
      options: [],
      formDataPayment: {},
      paymenttypes: [],
      paymenttypesFree: [],
      shippingtypes: [],
      paymenttypeIdSelected: -1,
      tmpPaymentID: uuid(),
      paymentType: "",
      shippingType: "",
      defLat: -16.5345619,
      defLng: -68.0900024,
      showFormAdd: false,
      addressFormData: {},
      addressFormConfig: {},
      addressInfo: [],
      scrollCheck: 0,
      tempcode: 0,
      firstTime: true,
      subtotalLabel: 0,
      discountLabel: 0,
      showDiscEvent: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.history.location.hash !== "#formstep1") {
      if (
        (!this.state.isValidPD || !this.state.isValidSD) &&
        this.state.firstTime
      ) {
        this.props.history.replace("/checkout/cart#formstep1", {
          step: "formstep1",
        });
      }
    }
    if (this.props.history.location.hash === "#formstep3") {
      if (!this.state.isValidPD || !this.state.isValidSD) {
        this.props.history.replace("/checkout/cart#formstep2", {
          step: "formstep2",
        });
      }
    }
  }

  getShopCart = async (page) => {
    let loginInfo = getLoginInfo();
    var cart = await getShopCart();
    let storesInfo = await getStoresInfo();
    if (loginInfo.userId) {
      await callApi("frontend", "shopStatus", {
        customerid: loginInfo.userId,
        cartsessionid: cart.id,
        storeid: getCurrentStore(),
        data: JSON.stringify(cart.shopCart),
        page: page,
      });
    }
  };
  componentDidMount = async () => {
    this.props.history.replace("/checkout/cart#formstep1", {
      step: "formstep1",
    });

    var exponeaId = getCookie("__exponea_etc__") || "0";

    //console.log("exoneaId", exponeaId);

    this.getShopCart("CHECKOUTSTART");

    let respC = await getShopCart("CHECKOUTSTART");

    var data = respC.shopCart;

    try {
      var dedicationMessage = getCookie(
        process.env.REACT_APP_ORG_NAME + "_dedicationMessage"
      )
        ? getCookie(process.env.REACT_APP_ORG_NAME + "_dedicationMessage")
        : "";
      var dedicationName = getCookie(
        process.env.REACT_APP_ORG_NAME + "_dedicationName"
      )
        ? getCookie(process.env.REACT_APP_ORG_NAME + "_dedicationName")
        : "";

      this.setState({
        ...this.state,
        dedicationInfo: {
          name: dedicationName,
          message: dedicationMessage,
        },
        dedicationName: dedicationName,
        dedicationMessage: dedicationMessage,
        exponeaId: exponeaId,
      });

      var tempcode;
      var _tempcode = getCookie(
        process.env.REACT_APP_ORG_NAME + "_tempoUserCode"
      );
      if (_tempcode) {
        if (_tempcode == "0") {
          tempcode = parseInt(Math.random() * (99999999 - 10000000) + 10000000);
          setCookie(
            process.env.REACT_APP_ORG_NAME + "_tempoUserCode",
            tempcode,
            0.16
          );
          //console.log("tiene mal",tempcode)
        } else {
          tempcode = parseInt(_tempcode);
          //console.log("tiene",tempcode)
        }
      } else {
        tempcode = parseInt(Math.random() * (99999999 - 10000000) + 10000000);
        setCookie(
          process.env.REACT_APP_ORG_NAME + "_tempoUserCode",
          tempcode,
          0.16
        );
        //console.log("nueva",tempcode)
      }
      this.setState({ tempcode: tempcode });

      //      console.log("shopCart", data);

      this.props.hideCart();

      var form = await getPersonalDataForm();
      var resp1 = await getStoresInfo();
      var options = await getOptions();
      var storeid = resp1.selectedStoreId;

      let catalogid = resp1.selectedCatalogId;
      let _pos = find(resp1.stores, storeid, "storeId");
      var storeName = resp1.stores[_pos].storeName;

      let resp2 = await callApi("frontend", "getPaymentTypesByStoreId", {
        languageid: getLanguage(),
        storeid: storeid,
      });
      let resp3 = await callApi("frontend", "getShippingTypes", {
        languageid: getLanguage(),
      });

      this.setState({
        ...this.state,
        isLoading: true,
        storeid: storeid,
        catalogid: catalogid,
        storeName: storeName,
        shopCart: data,
      });
      var validcouponcode = [];
      var login = getLoginInfo();
      if (login.userId) {
        let resp4 = await callApi("admin", "getCustomerById", {
          customerid: login.userId,
        });
        this.setState({
          ...this.state,
          addressInfo: resp4.ADDRESS,
          userDataInfo: resp4.USER,
          dedicationName: login.token.firstname,
          dedicationInfo: {
            name: login.token.firstname,
            message: dedicationMessage,
          },
        });
      }
      var totalProducts = 0;
      var eventid;

      data.products.map(function (key) {
        if (key.eventid) {
          eventid = key.eventid;
        }
        totalProducts = totalProducts + key.quantity;
      });

      if (eventid) {
        var cookieEventid = getCookie(
          process.env.REACT_APP_ORG_NAME + "_eventId"
        );

        if (cookieEventid == eventid) {
          var respE = await callApi("frontend", "getCustomerEventById", {
            languageid: getLanguage(),
            eventid: eventid,
          });
          if (respE.success) {
            let shippingType = "EVENT";
            //findValueById(resp3.rows, respE.EVENT.EVENTINFO.shippingtype, "SHIPPINGTYPEID", "TYPE")
            let shippingCost = 0;

            let pos = find(resp1.stores, respE.EVENT.STOREID, "storeId");
            let storeInfo = resp1.stores[pos];

            storeName = storeInfo.storeName;
            storeid = storeInfo.storeId;
            let catalogid = storeInfo.catalogId;

            if (storeid !== catalogid) {
              shippingCost = 15;
            }

            this.setState({
              ...this.state,
              storeid: respE.EVENT.STOREID,
              catalogid: catalogid,
              storeName: storeName,
              shippingCost: shippingCost,
            });
            this.setState({
              ...this.state,
              eventListInfo: {
                products: respE.PRODUCTS,
                eventid: eventid,
                eventtype: respE.EVENT.EVENTTYPE,
                eventimage: respE.EVENT.IMAGEPATH,
                eventdate: respE.EVENT.EVENTDATE,
                eventenddate: respE.EVENT.EVENTENDDATE,
                eventinfo: respE.EVENT.EVENTINFO,
                customerinfo: respE.EVENT.CUSTOMERINFO,
              },
              shippingOptionId: respE.EVENT.EVENTINFO.shippingtype,
              shippingType: shippingType,
            });
          }
          this.setState({
            ...this.state,
            btncolor: "secondary",
            showDiscEvent: true,
          });
        } else {
          var tempCart = localStorage.getItem(
            process.env.REACT_APP_ORG_NAME + "_activeCartV2"
          );
          var curCart = JSON.parse(tempCart);

          let fixedProducts = curCart.products;
          for (let k in fixedProducts) {
            delete fixedProducts[k]["eventid"];
          }

          let data = {
            id: curCart.id,
            userID: login.userId ? login.userId : -1,
            discount: curCart.discount,
            products: fixedProducts,
            subtotal: curCart.subtotal,
            total: curCart.total,
          };
          let _data = {
            id: curCart.id,
            data: data,
          };
          //console.log("data to send", _data)
          await callApi("frontend", "updateCart", _data);
          localStorage.setItem(
            process.env.REACT_APP_ORG_NAME + "_activeCartV2",
            JSON.stringify(data)
          );
        }
      }

      //console.log(data.products)
      var subtotal = 0,
        subtotalLabel = 0,
        discountLabel = 0;
      var discount = data.discount;
      for (let k in data.products) {
        var key = data.products[k];
        if (key.NEWPRICED) {
          discount =
            discount -
            (key.price * key.quantity - key.NEWPRICED * key.quantity);
          subtotal = subtotal + key.subtotal;
          discountLabel =
            discountLabel +
            (key.price * key.quantity - key.NEWPRICED * key.quantity);
        } else {
          subtotal = subtotal + key.price * key.quantity;
        }

        subtotalLabel = subtotalLabel + key.price * key.quantity;
      }

      var paymenttypes = [];
      var paymenttypesFree = [];

      resp2.rows.map(function (key) {
        if (key.DEFAULT_STATUSID == 0) {
          paymenttypesFree.push(key);
        } else {
          if (eventid) {
            if (key.TYPE == "DIRECT") {
              paymenttypes.push(key);
            }
          } else {
            paymenttypes.push(key);
          }
        }
      });

      var combos = calculateSkusDiscount(data.products);
      this.setState({
        ...this.state,
        options: options.rows,
        paymenttypes: paymenttypes,
        paymenttypesFree: paymenttypesFree,
        shippingtypes: resp3.rows,
        products: data.products,
        combos: combos,
        shopCartInfo: data,
        validcouponcode: validcouponcode,
        discount: toFixed(discount),
        cartsessionid: data.tmpSessionID,
        subtotal: toFixed(subtotal),
        subtotalLabel: toFixed(subtotalLabel),
        discountLabel: discountLabel,
        totalProducts: totalProducts,
        formDataPD: form.formData,
        formConfigPD: form.formConfig,
      });

      await preloadForm(this.state.formDataPayment, this.state);
      await preloadForm(this.state.formDataPD, this.state);
      await preloadForm(this.state.formDataPayment, this.state);

      await clearformData(this.state.formDataPD);
      var scrollCheck = window.scrollY;
      var resumeStyle = {
        position: "relative",
        rigth: "0px",
        top: scrollCheck,
        background: "#fff",
        zIndex: "999",
      };
      if (window.innerWidth <= 799) {
        resumeStyle = {};
      }

      this.setState({ resumeStyle: resumeStyle, scrollCheck: scrollCheck });

      let _cart = data;

      var products = [];
      var productids = [];
      var ProductBasketProducts = [];
      var items = [];
      _cart.products.map(function (key, index) {
        var variant = "";

        key.optionsselected.map(function (v) {
          variant = variant + "," + v.optionvalue;
        });
        variant = variant.substr(1, variant.length);
        products.push({
          id: key.productid,
          sku: key.productsku,
          name: key.productname,
          price: parseFloat(toFixed(key.price)),
          brand: "CasaIdeas",
          category: key.category,
          variant: variant,
          quantity: key.quantity,
        });
        items.push({
          item_id: key.productid + "",
          item_name: key.productname,
          coupon: "",
          discount: 0,
          affiliation: "Google Store",
          item_brand: "Casa Ideas",
          item_category: key.category,
          item_variant: variant,
          price: parseFloat(toFixed(key.price)),
          currency: "BOB",
          quantity: 1,
        });

        productids.push(key.productid + "");
        ProductBasketProducts.push({
          id: key.productid,
          sku: key.productsku,
          price: parseFloat(toFixed(key.price)),
          quantity: key.quantity,
        });
      });

      var objData = {
        current_view: "cart",
        current_list: "cart",
        current_currency: "BOB",
        userId: getLoginInfo().userId ? getLoginInfo().userId : "0",
        cart_products: products,
        string_searched: "",
        store_name: process.env.REACT_APP_ORG_NAME + "-" + storeName,
        googleDynamicRemarketing: {
          ecomm_prodid: productids,
          ecomm_totalvalue: parseFloat(toFixed(subtotal)),
          ecomm_pagetype: "cart",
        },
        criteoParams: {
          PageType: "BasketPage",
          ProductBasketProducts: ProductBasketProducts,
          email: "",
        },

        gdpr_marketing_status: "accepted",
        gdpr_statistics_status: "accepted",
        fb_pixel_track_name: "InitiateCheckout",
        fb_pixel_track_info: {
          value: parseFloat(toFixed(subtotal)),
          currency: "BOB",
          content_ids: "[" + productids.toString() + "]",
          num_items: totalProducts,
          content_type: "product",
        },
        checkoutStep: { step: 3, action: "checkout" },
        items: items,
      };
      setGTMEvent("shopcart", objData);

      document.addEventListener("scroll", () => {
        var scrollCheck = window.scrollY;
        if (scrollCheck > 271) {
          scrollCheck = scrollCheck - 271;
        } else {
          scrollCheck = 0;
        }
        var resumeStyle = {
          position: "relative",
          rigth: "0px",
          top: scrollCheck,
          background: "#fff",
          zIndex: "999",
        };
        if (window.innerWidth <= 799) {
          resumeStyle = {};
        }
        this.setState({
          scrollCheck: scrollCheck,
          resumeStyle: resumeStyle,
          windowHeight: window.innerHeight,
        });
      });

      var _genericCoupons = getCookie(
        process.env.REACT_APP_ORG_NAME + "_genericCoupons"
      )
        ? getCookie(process.env.REACT_APP_ORG_NAME + "_genericCoupons")
        : "";

      var genericCoupons = _genericCoupons.split(",");
      //console.log( "genericCoupons", genericCoupons, _genericCoupons)

      for (let c in genericCoupons) {
        if (genericCoupons[c] !== "") {
          validcouponcode.push({
            validcoupontempcode: this.state.tempcode,
            couponcode: genericCoupons[c],
          });
        }
      }

      if (login.userId) {
        let resp5 = await callApi("customer", "getValCouponByUser", {
          tempcode: tempcode,
        });

        resp5.couponid.map(function (key) {
          if (validcouponcode.indexOf(key.validcoupontempcode) < 0) {
            validcouponcode.push({
              validcoupontempcode: key.validcoupontempcode,
              couponcode: key.couponcode,
            });
          }
        });
      }

      if (validcouponcode.length > 0) {
        //await this.checkCartProducts();
        await this.prevalOrderDiscount();
      } else {
        tempcode = parseInt(Math.random() * (99999999 - 10000000) + 10000000);
        setCookie(
          process.env.REACT_APP_ORG_NAME + "_tempoUserCode",
          tempcode,
          0.16
        );
        //console.log("reset",tempcode)
        this.setState({ tempcode: tempcode });
      }

      this.setState({ ...this.state, isLoading: false });
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
    } finally {
      await this.checkCartProducts();
      await this.prevalOrderDiscount();

      this.setState({ redirect: false, isLoading: false, firstTime: false });
    }
  };

  refreshCoupon = async (event, add) => {
    var data = [];
    this.state.discountSTMessages.map(function (key) {
      data.push({
        validcoupontempcode: key.validcoupontempcode,
        validcouponcode: key.validcouponcode,
      });
    });
    this.state.discountSHMessages.map(function (key) {
      let pos = find(data, key.validcouponcode, "validcouponcode");
      //console.log("pos hay?", pos)
      if (pos < 0) {
        data.push({
          validcoupontempcode: key.validcoupontempcode,
          validcouponcode: key.validcouponcode,
        });
      }
    });
    //console.log("discountSHMessages", this.state.discountSHMessages)
    //console.log("discountSTMessages", this.state.discountSTMessages)
    //console.log("borrando", data)
    this.setState({
      validcouponcode: [],
      lockCouponButton: false,
      discountShipping: 0,
      discountSubtotal: 0,
      discountSHMessages: [],
      discountSTMessages: [],
      couponcode: "",
    });

    data.map(function (key) {
      var _data = {
        validcoupontempcode: key.validcoupontempcode,
        validcouponcode: key.validcouponcode,
      };
      callApi("customer", "unlockCoupon", _data);
    });
  };

  setLocation = async (event, add) => {
    //console.log("setLocation",event)
    if (event.lat) {
      this.setState({
        clatitude: event.lat,
        clongitude: event.lng,
        caddress: add,
      });
    }
  };

  showpopupdirections = async (event) => {
    this.setState({ isLoading: true });

    let form = await callApi("layaoutManager", "getLayoutTemplateByName", {
      layoutFile: "addressForm_" + getLanguage(),
      foldername: foldername + "private/",
    });
    let rendegObj = await getRenderObj(
      form.template[0],
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    rendegObj.curdata.config["btncolor"] = this.state.btncolor;
    //	 	console.log(rendegObj.curdata.config)
    this.setState({
      addressFormData: rendegObj.curdata.data,
      addressFormConfig: rendegObj.curdata.config,
      shippingFormId: form.template[0].componentid,
    });

    this.setState({
      modal4: true,
      showMap: false,
      showFormAdd: true,
      isLoading: false,
    });
  };

  setMyLocation = async (event) => {
    this.setState({ showMap: false });
    navigator.geolocation.getCurrentPosition(
      async function (position) {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;
        this.setState({
          latitude: latitude,
          longitude: longitude,
          showMap: true,
        });
      }.bind(this)
    );
  };

  selectPayment = async (event) => {
    console.log("selectPayment");
    await this.checkCartProducts();

    setTimeout(
      async function () {
        if (this.state.needReview || this.state.needShow) {
          this.setState({ isLoading: false });
          this.props.history.push("/checkout/cart#formstep1", {
            step: "formstep1",
          });

          window.location.reload();
        } else {
          this.setState({ ...this.state, isLoading: true, orderId: 0 });
          let pos = find(
            this.state.paymenttypes,
            parseInt(event.target.value),
            "PAYMENTTYPEID"
          );

          let key = this.state.paymenttypes[pos];
          let formData = JSON.parse(key.PARAMSIN);

          this.setState({
            paymenttypeIdSelected: parseInt(event.target.value),
            formDataPayment: formData,
            paymentType: key.TYPE,
            paymentMethod: key.PAYMENTNAME,
            statusid: key.DEFAULT_STATUSID,
          });

          await this.startOrder();

          if (convertNumber(this.state.orderId) > 0) {
            console.log("this.state.formDataPayment", this.state.formDataPayment, this.state)
            await preloadForm(this.state.formDataPayment, this.state);
          }
          setTimeout(
            async function () {
              this.setState({ isLoading: false });
            }.bind(this),
            2500
          );
        }
      }.bind(this),
      50
    );
  };

  selectPaymentFree = async (event) => {
    this.setState({ ...this.state, isLoading: true, orderId: 0 });
    let pos = find(
      this.state.paymenttypesFree,
      parseInt(event.target.value),
      "PAYMENTTYPEID"
    );

    let key = this.state.paymenttypesFree[pos];
    let formData = JSON.parse(key.PARAMSIN);

    this.setState({
      paymenttypeIdSelected: parseInt(event.target.value),
      formDataPayment: formData,
      paymentType: key.TYPE,
      paymentMethod: key.PAYMENTNAME,
      statusid: key.DEFAULT_STATUSID,
    });

    await this.startOrder();
    if (convertNumber(this.state.orderId) > 0) {
      await preloadForm(this.state.formDataPayment, this.state);
    }
    setTimeout(
      async function () {
        this.setState({ isLoading: false });
      }.bind(this),
      2500
    );
  };

  changePanel1 = (event) => {
    if (this.state.products.length > 0) {
      this.setState({
        ...this.state,
        isLoading: false,
        formActivePanel: 1,
        paymenttypeIdSelected: -1,
      });
      this.props.history.push("/checkout/cart#formstep1", {
        step: "formstep1",
      });
    }
    //console.log(this.props.history.location.state)
  };
  changePanel2 = async (event) => {
    //console.log(this.props.history.location.state)

    if (this.state.products.length > 0) {
      this.setState({ isLoading: true });
      var data = this.state.shopCart;

      var totalProducts = 0;
      data.products.map(function (key) {
        totalProducts = totalProducts + key.quantity;
      });
      var subtotal = 0,
        subtotalLabel = 0,
        discountLabel = 0;
      var discount = data.discount;

      for (let k in data.products) {
        var key = data.products[k];
        if (key.NEWPRICED) {
          discount =
            discount -
            (key.price * key.quantity - key.NEWPRICED * key.quantity);
          subtotal = subtotal + key.subtotal;
          discountLabel =
            discountLabel +
            (key.price * key.quantity - key.NEWPRICED * key.quantity);
        } else {
          subtotal = subtotal + key.price * key.quantity;
        }

        subtotalLabel = subtotalLabel + key.price * key.quantity;
      }
      var combos = calculateSkusDiscount(data.products);
      //console.log("changepanel2", subtotal)
      this.setState({
        ...this.state,
        products: data.products,
        subtotal: toFixed(subtotal),
        combos: combos,
        subtotalLabel: toFixed(subtotalLabel),
        discountLabel: discountLabel,
      });

      var loginInfo = getLoginInfo();
      if (loginInfo.userId != null || event === "guest") {
        let _cart = this.state.shopCart;

        var products = [];
        var productids = [];
        var ProductBasketProducts = [];
        _cart.products.map(function (key) {
          var variant = "";

          key.optionsselected.map(function (v) {
            variant = variant + "," + v.optionvalue;
          });
          variant = variant.substr(1, variant.length);
          products.push({
            id: key.productid,
            sku: key.productsku,
            name: key.productname,
            price: parseFloat(toFixed(key.price)),
            brand: "CasaIdeas",
            category: key.category,
            variant: variant,
            quantity: key.quantity,
          });
          productids.push(key.productid + "");
          ProductBasketProducts.push({
            id: key.productid,
            sku: key.productsku,
            price: parseFloat(toFixed(key.price)),
            quantity: key.quantity,
          });
        });
        await getStoresInfo();
        //let _pos = find (storesInfo.stores,this.state.storeid, "storeId"  )
        //var storeName = storesInfo.stores[_pos].storeName

        setGTMEvent("checkoutStep", {
          event: "checkoutStep",
          checkoutStep: { step: 4, action: "checkout" },
        });
        //console.log("-")
        mergeFormData(this.state.formDataPD, this.state.userDataInfo);

        //customer data
        setTimeout(
          function () {
            this.setState({
              ...this.state,
              isLoading: false,
              formActivePanel: 2,
              lockformPD: false,
              lockformSD: false,
              isValidPD: false,
              isValidSD: false,
              userId: loginInfo.userId ? loginInfo.userId : 0,
              formDataSD: {},
            });
          }.bind(this),
          1
        );

        this.props.history.push("/checkout/cart#formstep2", {
          step: "formstep2",
        });
        this.getShopCart("CHECKOUTPERSONALDATA");
      } else {
        this.setState({
          modal1: true,
          isLoading: false,
          needLogin: false,
          paymenttypeIdSelected: -1,
        });
      }
    }
  };

  //prevalidates coupons step 1
  changePanel3 = async (event) => {
    /*********BLOOMREACH*********/
    var objBloomreach_CheckoutShipping = {
      step_number: "1",
      step_title: "Shipping Method Selection",
      tipo_entrega: this.state.shippingLabel,
      origen: "web",
      total_price: this.state.subtotal,
    };
    //console.log(objBloomreach_CheckoutShipping)
    window.exponea.track("checkout_shipping", objBloomreach_CheckoutShipping);
    /*********BLOOMREACH*********/

    this.getShopCart("CHECKOUTPAYMENT");
    let respC = await getShopCart("CHECKOUTPAYMENT");
    var data = respC.shopCart;
    var items = [];
    var subtotal = 0;
    var discount = data.discount;
    data.products.map(function (key, index) {
      var variant = "";
      key.optionsselected.map(function (v) {
        variant = variant + "," + v.optionvalue;
      });
      variant = variant.substr(1, variant.length);

      if (key.NEWPRICED) {
        discount =
          discount - (key.price * key.quantity - key.NEWPRICED * key.quantity);
        subtotal = subtotal + key.subtotal;
      } else {
        subtotal = subtotal + key.price * key.quantity;
      }
      items.push({
        item_id: key.productid + "",
        item_name: key.productname,
        coupon: "",
        discount: 0,
        affiliation: "Google Store",
        item_brand: "Casa Ideas",
        item_category: key.category,
        item_variant: variant,
        price: parseFloat(toFixed(key.price)),
        currency: "BOB",
        quantity: 1,
      });
    });

    var objData = {
      shipping_tier: this.state.shippingLabel,
      value: parseFloat(toFixed(subtotal)),
      items: items,
    };
    setGTMEvent("add_shipping_info", objData);

    if (
      this.state.products.length > 0 &&
      this.state.isValidPD &&
      this.state.isValidSD
    ) {
      //console.log("changePanel3")
      await this.prevalOrderDiscount();
      this.props.history.push("/checkout/cart#formstep3", {
        step: "formstep3",
      });
      this.setState({
        ...this.state,
        isLoading: false,
        itemsGTM: items,
        formActivePanel: 3,
        paymenttypeIdSelected: -1,
      });
    }
  };

  changeHandlerA = async (event) => {
    var formData = changeHandler(event, this.state.addressFormData);
    this.setState({ addressFormData: formData });

    if (event.key === "Enter") {
      this.saveTempLocation(event);
    }
  };

  changeHandlerCartInfo = async (event) => {
    if (event.target.name === "couponcode") {
      this.setState({ [event.target.name]: event.target.value.toUpperCase() });
      if (event.key === "Enter") {
        this.validCoupon(event);
      }
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  saveDedicationForm = (event) => {
    this.setState({
      dedicationInfo: {
        name: this.state.dedicationName,
        message: this.state.dedicationMessage,
      },
    });

    setCookie(
      process.env.REACT_APP_ORG_NAME + "_dedicationMessage",
      this.state.dedicationMessage,
      0.5
    );
    setCookie(
      process.env.REACT_APP_ORG_NAME + "_dedicationName",
      this.state.dedicationName,
      0.5
    );
  };

  cancelDedicationForm = (event) => {
    this.setState({
      dedicationName: this.state.dedicationInfo.name,
      dedicationMessage: this.state.dedicationInfo.message,
    });
  };

  deleteCoupon = async (event) => {
    var validcouponcode = findObjName(event);
    this.setState({ isLoading: true });

    //console.log(event.target, validcouponcode)

    var discountSTMessages = this.state.discountSTMessages;
    var discountSHMessages = this.state.discountSHMessages;
    let pos1 = find(discountSTMessages, validcouponcode, "validcouponcode");
    let pos2 = find(discountSHMessages, validcouponcode, "validcouponcode");

    //console.log("discountSTMessages antes",discountSTMessages, pos1)
    //console.log("discountSHMessages antes",discountSHMessages, pos2)

    var data;
    if (pos1 >= 0) {
      data = {
        validcoupontempcode: discountSTMessages[pos1].validcoupontempcode,
        validcouponcode: validcouponcode,
      };
    }
    if (pos2 >= 0) {
      data = {
        validcoupontempcode: discountSHMessages[pos2].validcoupontempcode,
        validcouponcode: validcouponcode,
      };
    }

    let respCC = await callApi("customer", "unlockCoupon", data);

    var validcouponcodes = this.state.validcouponcode;

    //console.log("validcouponcode antes",validcouponcodes)
    //console.log("validcouponcode a borrar",validcouponcode)
    if (respCC.success) {
      var _genericCoupons = getCookie(
        process.env.REACT_APP_ORG_NAME + "_genericCoupons"
      )
        ? getCookie(process.env.REACT_APP_ORG_NAME + "_genericCoupons")
        : "";
      var genericCoupons = _genericCoupons.split(",");
      var posg = genericCoupons.indexOf(validcouponcode);
      //console.log("genericCoupons",genericCoupons, posg, validcouponcode)
      if (posg >= 0) {
        //console.log("borrando cupon")
        genericCoupons.splice(posg, 1);
        //console.log("borrando",genericCoupons)
        setCookie(
          process.env.REACT_APP_ORG_NAME + "_genericCoupons",
          genericCoupons.toString(),
          0.16
        );
      } else {
        //console.log("no es generido no se borra")
      }

      var pos = find(validcouponcodes, validcouponcode, "couponcode");
      if (pos >= 0) {
        validcouponcodes.splice(pos, 1);
        pos = find(validcouponcodes, validcouponcode, "couponcode");
        if (pos >= 0) {
          validcouponcodes.splice(pos, 1);
        }
      }

      if (pos1 >= 0) {
        discountSTMessages.splice(pos1, 1);
      }
      if (pos2 >= 0) {
        discountSHMessages.splice(pos1, 1);
      }
      var discountSubtotal = 0;
      discountSTMessages.map(function (key) {
        discountSubtotal = discountSubtotal + parseFloat(key.discountamount);
      });

      var discountShipping = 0;
      discountSHMessages.map(function (key) {
        discountShipping = discountShipping + parseFloat(key.discountamount);
      });
      //console.log("validcouponcode despues",validcouponcodes)
      this.setState({
        lockCouponButton: false,
        validcouponcode: validcouponcodes,
        discountSTMessages: discountSTMessages,
        discountShipping: discountShipping,
        discountSubtotal: discountSubtotal,
        discountSHMessages: discountSHMessages,
        isLoading: false,
      });

      showMessage(
        toast,
        "Cup\u00F3n eliminado correctamente, puedes usarlo en otra compra"
      );
    } else {
      showError(
        toast,
        "Hubo un error eliminando el cup\u00F3n, intente nuevamente o refresque la p\u00E1gina"
      );
    }
    this.setState({ isLoading: false });
  };

  validCoupon = async (event) => {
    var tryoutsCoupon = getCookie(
      process.env.REACT_APP_ORG_NAME + "_tryoutsCoupon"
    )
      ? getCookie(process.env.REACT_APP_ORG_NAME + "_tryoutsCoupon")
      : 0;
    var maxtryoutsCoupon = 999;

    if (window.location.href.indexOf("www.casaideas.com.bo") >= 0) {
      maxtryoutsCoupon = 4;
    }

    //		console.log("tryoutsCoupon",maxtryoutsCoupon)
    let pos1 = find(
      this.state.discountSHMessages,
      this.state.couponcode,
      "validcouponcode"
    );
    let pos2 = find(
      this.state.discountSTMessages,
      this.state.couponcode,
      "validcouponcode"
    );

    if (pos1 < 0 && pos2 < 0) {
      if (tryoutsCoupon < maxtryoutsCoupon) {
        this.setState({ isLoading: true });
        var data = {
          couponcode: this.state.couponcode,
          tempcode: this.state.tempcode,
        };
        let respCC = await callApi("customer", "prevalCoupon", data);
        if (respCC.success) {
          var login = getLoginInfo();
          var needLogin = false;
          for (let c in respCC.couponid) {
            if (respCC.couponid[c].isloginrequired === "YES") {
              needLogin = true;
            }
          }

          if (needLogin && !login.userId) {
            this.setState({
              modal1: true,
              isLoading: false,
              needLogin: needLogin,
            });
          } else {
            var validcouponcode = this.state.validcouponcode;
            //validcouponcode.push(this.state.couponcode )

            var _couponcode = this.state.couponcode;
            respCC.couponid.map(function (key) {
              let pos = find(validcouponcode, _couponcode, "couponcode");
              if (pos < 0) {
                validcouponcode.push({
                  couponcode: _couponcode,
                  validcoupontempcode: key.tempcode,
                });
                if (key.isgeneric) {
                  var genericCoupons = getCookie(
                    process.env.REACT_APP_ORG_NAME + "_genericCoupons"
                  )
                    ? getCookie(
                        process.env.REACT_APP_ORG_NAME + "_genericCoupons"
                      )
                    : "";

                  if (genericCoupons.indexOf(_couponcode) < 0) {
                    genericCoupons = genericCoupons + "," + _couponcode;
                    //console.log("genericCoupons",genericCoupons)
                    setCookie(
                      process.env.REACT_APP_ORG_NAME + "_genericCoupons",
                      genericCoupons,
                      0.16
                    );
                  } else {
                    //console.log("ya tiene")
                  }
                }
              }
            });
            //console.log("validcouponcode",validcouponcode)
            this.setState({ validcouponcode: validcouponcode });

            //console.log("validcouponcode",validcouponcode)
            //console.log("_couponcode",	 _couponcode)
            await this.prevalOrderDiscount();

            if (
              this.state.discountSHMessages.length > 0 ||
              this.state.discountSTMessages.length > 0
            ) {
              var isValidCouponCode = false;
              this.state.discountSHMessages.map(function (key) {
                if (key.validcouponcode == _couponcode) {
                  isValidCouponCode = true;
                }
              });

              this.state.discountSTMessages.map(function (key) {
                if (key.validcouponcode == _couponcode) {
                  isValidCouponCode = true;
                }
              });

              if (isValidCouponCode) {
                showMessage(
                  toast,
                  "Felicidades, Tu cup\u00F3n fue ingresado correctamente"
                );
              } else {
                showError(
                  toast,
                  "Lo sentimos, el cup\u00F3n no cumple con los requisitos de la promoci\u00F3n"
                );
              }
            } else {
              showError(
                toast,
                "Lo sentimos, el cup\u00F3n no cumple con los requisitos de la promoci\u00F3n"
              );
            }

            setCookie(
              process.env.REACT_APP_ORG_NAME + "_tryoutsCoupon",
              0,
              0.01
            );
          }
        } else {
          tryoutsCoupon++;
          setCookie(
            process.env.REACT_APP_ORG_NAME + "_tryoutsCoupon",
            tryoutsCoupon,
            1
          );

          showError(
            toast,
            "Lo sentimos, el cup\u00F3n es inv\u00E1lido le quedan " +
              (maxtryoutsCoupon - tryoutsCoupon) +
              " intentos"
          );

          this.setState({ isLoading: false, couponcode: "" });
        }
      } else {
        showError(
          toast,
          "Lo sentimos, ya no tiene m\u00E1s intentos, consulta con el Call Center"
        );
      }
    } else {
      this.setState({ isLoading: false, couponcode: "" });
      showError(toast, "El Cup\u00F3n ya est\u00E1 siendo utilizado");
    }
  };

  //prevalidates coupons step 1
  prevalOrderDiscount = async (event) => {
    this.setState({ isLoading: true });
    let resp1 = await getStoresInfo();

    let cartsessionid = resp1.tmpSessionID;
    var addressinfo;

    if (this.state.activeFormSD) {
      var formData = parseFormData(
        this.state.formDataSD[this.state.activeFormSD].formData
      );

      if (formData.directions) {
        let pos = find(
          this.state.addressInfo,
          parseInt(formData.directions),
          "ADDRESSID"
        );
        if (pos >= 0) {
          //console.log("pos", pos, this.state.addressInfo[pos].ADDRESSINFO)

          let add = JSON.parse(this.state.addressInfo[pos].ADDRESSINFO);
          formData["addressinfo"] = add;
        }
      }
      addressinfo = JSON.stringify(formData);
    }

    var _formDataPD = parseFormData(this.state.formDataPD);

    //var products =resp1.products
    var city = "";
    try {
      city = findValueById(
        this.state.formDataPD.city.values,
        this.state.formDataPD.city.value,
        "value",
        "text"
      );
    } catch (Exc) {
      city = "NOCITY";
      console.log("NO CITY");
    }

    var _data = {
      storeid: this.state.storeid,
      paymentMethod: this.state.paymentMethod,
      statusid: this.state.statusid,
      languageid: getLanguage(),
      shippingtypeid: this.state.shippingOptionId,
      shippingType: this.state.shippingType,
      shippingLabel: this.state.shippingLabel,
      subtotal: this.state.subtotal,
      shippingcost: this.state.shippingCost,
      paymnettype: this.state.paymentType,
      paymnettypeid: this.state.paymenttypeIdSelected,
      customerid: this.state.userId,
      city: city,
      addressinfo: addressinfo,
      invoiceid: 0,
      discount: this.state.discount /*CHECK!!!*/,
      cartsessionid: cartsessionid,
      products: this.state.products,
      validcouponcode: this.state.validcouponcode,
    };

    //console.log(_formDataPD)
    var data = { ..._data, ..._formDataPD };

    let resp = await callApi("customer", "prevalOrderDiscount", data);
    //console.log(resp)
    if (resp.success) {
      setCookie(
        process.env.REACT_APP_ORG_NAME + "_tempoUserCode",
        this.state.tempcode,
        0.16
      );

      var discountSubtotal = 0;
      var ispositivebalancetoshipp = false;

      var lockCouponButton = false;

      if (
        resp.discountSTMessages.length === 0 &&
        resp.discountSTMessages.length === 0
      ) {
        lockCouponButton = false;
      } else {
        resp.discountSHMessages.map(function (key) {
          if (key.isappend === "NO") {
            lockCouponButton = true;
          }
        });

        resp.discountSTMessages.map(function (key) {
          if (key.isappend === "NO") {
            lockCouponButton = true;
          }
        });

        lockCouponButton = true;
        resp.discountSHMessages.map(function (key) {
          var totalcustomerused = 0;
          for (let i in resp.discountSHMessages) {
            if (resp.discountSHMessages[i].couponid == key.couponid) {
              totalcustomerused++;
            }
          }
          //console.log("el max es",key.totalcustomersupported,"usa", totalcustomerused )
          if (key.totalcustomersupported > totalcustomerused) {
            lockCouponButton = false;
          }
        });

        if (lockCouponButton) {
          resp.discountSTMessages.map(function (key) {
            var totalcustomerused = 0;
            for (let i in resp.discountSTMessages) {
              if (resp.discountSTMessages[i].couponid == key.couponid) {
                totalcustomerused++;
              }
            }
            //console.log("el max es",key.totalcustomersupported,"usa", totalcustomerused )
            if (key.totalcustomersupported > totalcustomerused) {
              lockCouponButton = false;
            }
          });
        }
      }

      resp.discountSTMessages.map(function (key) {
        discountSubtotal = discountSubtotal + parseFloat(key.discountamount);
        //var totalcustomerused = 0
        if (key.ispositivebalancetoshipp === "YES") {
          ispositivebalancetoshipp = true;
        }
      });

      var discountShipping = resp.discountShipping;
      var isDisctountShipp = false;
      if (ispositivebalancetoshipp) {
        if (
          discountShipping < this.state.shippingCost &&
          this.state.shippingCost > 0
        ) {
          let _subtotal = this.state.subtotal;
          let subtotal = parseFloat(_subtotal);

          if (discountSubtotal > subtotal) {
            let _discountShipping = discountSubtotal - subtotal;

            if (this.state.shippingCost < _discountShipping) {
              discountShipping = this.state.shippingCost;
            } else {
              discountShipping = _discountShipping;
            }

            isDisctountShipp = true;
          }
        }
      }

      //console.log("ispositivebalancetoshipp",ispositivebalancetoshipp)
      //console.log("discountSHMessages",resp.discountSHMessages)
      //console.log("discountSTMessages",resp.discountSTMessages)
      //console.log("discountSubtotal",discountSubtotal)
      this.setState({
        ...this.state,
        discountShipping: discountShipping,
        discountSubtotal: discountSubtotal,
        discountSHMessages: resp.discountSHMessages,
        discountSTMessages: resp.discountSTMessages,
        lockCouponButton: lockCouponButton,
        couponcode: "",
        isLoading: false,
        isDisctountShipp: isDisctountShipp,
      });
    }else{
      window.location.reload();
    }
  };

  checkCartProducts = async (event) => {
    this.setState({ isLoading: true });
    let resp1 = await getStoresInfo();
    let cartsessionid = resp1.tmpSessionID;

    let data = {
      storeid: this.state.catalogid,
      cartsessionid: cartsessionid,
      products: this.state.products,
      validcouponcode: this.state.validcouponcode,
    };

    let resp = await callApi("customer", "checkCartProducts", data);
    //console.log("checkCartProducts", resp);
    if (resp.success) {
      if (resp.newSkus) {
        let _data = await getShopCart("CHECKOUTSTART");
        let discount = calculateDiscount(resp.newSkus);

        let data = _data.shopCart;
        data["products"] = resp.newSkus;
        data["discount"] = discount;

        //        console.log("data", data);

        var subtotal = 0,
          subtotalLabel = 0,
          discountLabel = 0;

        for (let k in data.products) {
          var key = data.products[k];
          if (key.NEWPRICED) {
            discount =
              discount -
              (key.price * key.quantity - key.NEWPRICED * key.quantity);
            subtotal = subtotal + key.subtotal;
            discountLabel =
              discountLabel +
              (key.price * key.quantity - key.NEWPRICED * key.quantity);
          } else {
            subtotal = subtotal + key.price * key.quantity;
          }

          subtotalLabel = subtotalLabel + key.price * key.quantity;
        }

        var totalProducts = 0;
        data.products.map(function (key) {
          totalProducts = totalProducts + key.quantity;
        });

        localStorage.setItem(
          process.env.REACT_APP_ORG_NAME + "_activeCartV2",
          JSON.stringify(data)
        );

        //console.log("checkcartProducts", subtotal)
        this.setState({
          products: resp.newSkus,
          needReview: resp.needReview,
          needShow: resp.needShow,
          discount: toFixed(discount),
          cartsessionid: data.tmpSessionID,
          subtotal: toFixed(subtotal),
          subtotalLabel: toFixed(subtotalLabel),
          discountLabel: discountLabel,
          totalProducts: totalProducts,
        });
      }
    }else{
      window.location.reload();
    }
  };

  saveTempLocation = async (event) => {
    var resp = await validateForm(this.state.addressFormData, toast);

    if (resp.isValid) {
      //console.log(this.state.addressFormData)
      //console.log(this.state.addressFormData.address.value)
      this.props.toggleListener();
      this.setState({
        showMap: true,
        showFormAdd: false,
        address: this.state.addressFormData.address.value,
        city: this.state.addressFormData.city.value,
      });
    }
  };

  confirmLocation = async (event) => {
    this.setState({ isLoading: true });
    try {
      let _formData = this.state.addressFormData;
      _formData = parseFormData(_formData);

      var directionsLabel = _formData["alias"].toUpperCase();
      var addLabel = _formData["address"];

      var shippingCost =
        event.shippingCost + parseFloat(this.state.baseShippingCost);

      _formData["latitude"] = event.lat;
      _formData["longitude"] = event.lng;
      _formData["shippingCost"] = event.shippingCost;
      _formData["zonecode"] = event.zonecode;

      shippingCost =
        event.shippingCost +
        parseFloat(this.state.formDataSD[this.state.activeFormSD].shippingCost);

      var data = {
        shippingtypeid: this.state.shippingOptionId,
        addressinfo: JSON.stringify(_formData),
        customerid: this.state.userId,
      };
      if (this.state.userId === 0) {
        const directions =
          this.state.formDataSD[this.state.activeFormSD].formData.directions;
        directions.values.push({
          value: 0,
          text: directionsLabel,
          aditionalText: addLabel,
        });
        directions.value = 0;
        let addressInfo = [
          { ADDRESSID: 0, ADDRESSINFO: JSON.stringify(_formData) },
        ];
        this.setState({ addressInfo: addressInfo });
      } else {
        let resp = await callApi("frontend", "setCustomerAddress", data);

        if (resp.success) {
          const directions =
            this.state.formDataSD[this.state.activeFormSD].formData.directions;
          if (directions) {
            directions.values.push({
              value: resp.message,
              text: directionsLabel,
              aditionalText: addLabel,
            });
            directions.value = resp.message;
            let addressInfo = this.state.addressInfo;
            addressInfo.push({
              ADDRESSID: resp.message,
              ADDRESSINFO: JSON.stringify(_formData),
            });
            this.setState({ addressInfo: addressInfo });
            this.props.toggleListener();
          }
        } else {
          showError(toast, getDefValues().errorSettingAddress.toUpperCase());
        }
      }

      this.setState({
        /*isLoading:false, */ modal4: false,
        showMap: false,
        showFormAdd: true,
        haveDirection: true,
        shippingCost: shippingCost,
      });
      await this.prevalOrderDiscount();
    } catch (Excep) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", Excep);
    }
  };

  changeHandlerI = async (event) => {
    //let resp = await validateForm(this.state.formDataI, toast,event);
    //this.setState({formDataI:resp.formData});

    var formData = changeHandler(event, this.state.formDataI);
    this.setState({ formDataI: formData });

    if (event.key === "Enter") {
      this.confirmInvoce(event);
    }
  };

  confirmInvoce = async () => {
    if (
      this.state.products.length > 0 &&
      this.state.isValidPD &&
      this.state.isValidSD
    ) {
      this.setState({ isLoading: true });
      var event = {
        target: { name: "", value: this.state.paymenttypes[0].PAYMENTTYPEID },
      };
      await this.selectPayment(event);

      setTimeout(
        function () {
          this.setState({
            ...this.state,
            isLoading: false,
            formActivePanel: 3,
            modal3: false,
          });
        }.bind(this),
        1
      );
    }
  };
  login = async (event) => {
    try {
      this.setState({ isLoading: true });
      var resp = await login(this.state.formData, this.state.rememberme, toast);
      if (resp.success) {
        this.setState({ isLoading: false, modal1: false, reloadPage: true });
        window.location.reload();
      } else {
        this.setState({ isLoading: false });
      }
    } catch (Excep) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", Excep);
    }
  };

  guest = async (event) => {
    this.changePanel2("guest");
    this.setState({ modal1: false });
  };

  changeHandlerL = async (event) => {
    //let resp = await validateForm(this.state.formData, toast,event);
    //this.setState({formData:resp.formData});

    var formData = changeHandler(event, this.state.formData);
    this.setState({ formData: formData });

    if (event.key === "Enter") {
      this.login(event);
    }
  };
  changeHandler = async (event) => {
    //console.log(window.PolyGeometry);

    //let resp = await validateForm(this.state.formDataPD, toast,event);
    //this.setState({formDataPD:resp.formData});

    var formData = changeHandler(event, this.state.formDataPD);
    this.setState({ formDataPD: formData });

    if (event.key === "Enter") {
      this.savePersonalData(event);
    }
  };

  selectPoint = async (event, map, coord) => {
    //const { latLng } = coord;
    //const lat = latLng.lat();
    //const lng = latLng.lng();
  };

  changeHandler2 = async (event) => {
    var normal = true;
    try {
      var actions;
      var fieldName;
      var fieldValue;
      if (event.target.name) {
        actions = event.target.name.split("__");
        fieldName = event.target.name;
        fieldValue = event.target.value;
      } else {
        actions = event.target.parentNode.name.split("__");
        fieldName = event.target.parentNode.name;
        fieldValue = event.target.parentNode.value;
      }

      var pos = actions.indexOf("ADD");
      if (pos >= 0) {
        normal = false;
      }
    } catch (ex) {}

    if (normal) {
      var formDataSD = [];
      for (let f in this.state.formDataSD) {
        formDataSD[f] = this.state.formDataSD[f];
        if (f == this.state.activeFormSD) {
          //let resp = await validateForm(  	formDataSD[f].formData , toast,event);
          //formDataSD[f].formData = resp.formData

          var formData = changeHandler(event, formDataSD[f].formData);
          formDataSD[f].formData = formData;
          //	  			console.log("formData",formData)
        }
      }

      //console.log ("fieldName", fieldName)

      if (fieldName === "directions") {
        let pos = find(
          this.state.addressInfo,
          parseInt(fieldValue),
          "ADDRESSID"
        );
        if (pos >= 0) {
          //console.log(this.state.addressInfo[pos].ADDRESSINFO)
          let addData = JSON.parse(this.state.addressInfo[pos].ADDRESSINFO);
          let shippingCost =
            parseFloat(
              this.state.formDataSD[this.state.activeFormSD].shippingCost
            ) + parseFloat(addData.shippingCost);

          this.setState({ shippingCost: shippingCost });

          if (
            this.state.discountSHMessages.length >= 0 ||
            this.state.discountSTMessages.length >= 0
          ) {
            await this.prevalOrderDiscount();
          }
        }
      }

      if (fieldName === "storeName") {
        //console.log("dir id", fieldValue, this.state.formDataSD);
        let pos = find(this.state.formDataSD, 1, "formOptionID");
        const formDataPickUp = this.state.formDataSD[pos].formData;
        let pos2 = find(formDataPickUp.storeName.values, fieldValue, "value");
        let hours = formDataPickUp.storeName.values[pos2].hours;
        //console.log(formDataPickUp, hours);
        formDataPickUp["shippingday"]["highlighted"] = hours;
        //console.log("final", formDataPickUp["shippingday"]);
      }

      this.setState({ formDataSD: formDataSD });
      if (event.key === "Enter") {
        this.saveShippingData(event);
      }
    } else {
      //var field =  this.state.formDataSD[this.state.activeFormSD].formData[actions[2]]
      this.showpopupdirections();
    }
  };

  changeHandler3 = async (event) => {
    let resp = await validateForm(this.state.formDataPayment, toast, event);
    this.setState({ formDataPayment: resp.formData });
  };

  changeForm = async (event) => {
    var shippingCost = toFixed(
      parseFloat(this.state.formDataSD[event.target.value].shippingCost)
    );
    var shippingOptionId =
      this.state.formDataSD[event.target.value].formOptionID;
    var shippingType = findValueById(
      this.state.shippingtypes,
      shippingOptionId,
      "SHIPPINGTYPEID",
      "TYPE"
    );
    var shippingLabel = findValueById(
      this.state.shippingtypes,
      shippingOptionId,
      "SHIPPINGTYPEID",
      "SHIPPINGNAME"
    );

    if (this.state.formDataSD[event.target.value].formData.directions) {
      if (this.state.addressInfo.length > 0) {
        if (
          this.state.formDataSD[event.target.value].formData.directions.value
        ) {
          var pos = find(
            this.state.addressInfo,
            this.state.formDataSD[event.target.value].formData.directions.value,
            "ADDRESSID"
          );
          let addData = JSON.parse(this.state.addressInfo[pos].ADDRESSINFO);
          shippingCost = toFixed(
            parseFloat(addData.shippingCost) + parseFloat(shippingCost)
          );
          const formDataSD = this.state.formDataSD;
          formDataSD[event.target.value].formData.directions.value =
            this.state.addressInfo[pos].ADDRESSID;
        } else {
          shippingCost = toFixed(parseFloat(shippingCost));
        }

        this.setState({ shippingCost: shippingCost });
      }
    }

    this.setState({
      ...this.state,
      activeFormSD: event.target.value,
      shippingLabel: shippingLabel,
      shippingType: shippingType,
      shippingCost: shippingCost,
      baseShippingCost: shippingCost,
      shippingOptionId: shippingOptionId,
    });

    this.setState({
      isLoading: false,
      discountSHMessages: [],
      discountSTMessages: [],
    });
    await this.prevalOrderDiscount();
  };

  editPersonalData = (event) => {
    this.setState({ lockformPD: false, isValidPD: false });
  };
  editShippingData = (event) => {
    this.setState({ lockformSD: false, isValidSD: false });
  };

  savePersonalData = async (event) => {
    var resp = await validateForm(this.state.formDataPD, toast);
    if (resp.isValid) {
      if (this.state.eventListInfo) {
        var formData = {};
        this.setState({
          isValidPD: true,
          isValidSD: true,
          isLoading: false,
          finalFormData: formData,
        });
        this.changePanel3();
      } else {
        this.setState({ isLoading: true });
        let _formData = this.state.formDataPD;
        _formData = parseFormData(_formData);
        var formDataSD = await getShippingMethodsForm(_formData);
        this.setState({
          showPersonalData: false,
          lockformPD: true,
          showShippingData: true,
          isValidPD: true,
          formDataSD: formDataSD,
          activeFormSD: 0,
          isLoading: false,
        });
        var shippingCost = toFixed(
          parseFloat(this.state.formDataSD[0].shippingCost)
        );
        var shippingOptionId = this.state.formDataSD[0].formConfig.formid;
        var shippingType = findValueById(
          this.state.shippingtypes,
          shippingOptionId,
          "SHIPPINGTYPEID",
          "TYPE"
        );
        var shippingLabel = findValueById(
          this.state.shippingtypes,
          shippingOptionId,
          "SHIPPINGTYPEID",
          "SHIPPINGNAME"
        );
        var city = findValueById(
          this.state.formDataPD.city.values,
          this.state.formDataPD.city.value,
          "value",
          "text"
        );
        this.setState({
          baseShippingCost: shippingCost,
          shippingCost: shippingCost,
          city: city,
          shippingLabel: shippingLabel,
          shippingOptionId: shippingOptionId,
          shippingType: shippingType,
        });
        this.setState({
          isLoading: false,
          discountSHMessages: [],
          discountSTMessages: [],
        });
        if (this.state.formDataSD[0].formData.directions) {
          if (this.state.addressInfo.length > 0) {
            if (this.state.formDataSD[0].formData.directions.value) {
              var pos = find(
                this.state.addressInfo,
                this.state.formDataSD[0].formData.directions.value,
                "ADDRESSID"
              );
              let addData = JSON.parse(this.state.addressInfo[pos].ADDRESSINFO);
              shippingCost = toFixed(
                parseFloat(addData.shippingCost) + parseFloat(shippingCost)
              );
              const formDataSD = this.state.formDataSD;
              formDataSD[0].formData.directions.value =
                this.state.addressInfo[pos].ADDRESSID;
            } else {
              shippingCost = toFixed(parseFloat(shippingCost));
            }

            this.setState({ shippingCost: shippingCost });
          }
        }
      }
      await this.prevalOrderDiscount();
      getShopCart("CHECKOUTADDRESS");
    } else {
      this.setState({ formData1: resp.formData });
    }
  };
  saveShippingData = async (event) => {
    var resp = { isValid: false };
    //var formName
    for (let f in this.state.formDataSD) {
      if (f == this.state.activeFormSD) {
        var formData = this.state.formDataSD[f].formData;
        //console.log("formData:::",formData)
        resp = await validateForm(formData, toast);
      }
    }
    //console.log(this.state.formDataSD)
    if (resp.isValid) {
      this.setState({ isLoading: true });
      setTimeout(
        function () {
          //console.log(formData)
          this.setState({
            showShippingData: false,
            lockformSD: true,
            isValidSD: true,
            isLoading: false,
            finalFormData: formData,
          });

          if (formData.directions) {
            this.setState({ haveDirection: true });
          }

          this.changePanel3();
        }.bind(this),
        1
      );
    } else {
      this.setState({ ...this.state });
    }
  };

  startOrder = async (event) => {
    try {
      clearInterval(this.state.intervalId);
      this.setState({ discountSHMessages: [], discountSTMessages: [] });
      var shippingCost = this.state.shippingCost;
      var resp1 = this.state.shopCart;

      var cartsessionid = resp1.id;
      var products = resp1.products;
      if (products.length > 0) {
        await this.prevalOrderDiscount();

        this.setState({ isLoading: true });

        var addressinfo = {};

        if (this.state.eventListInfo) {
          let eventinfo = this.state.eventListInfo.eventinfo;
          eventinfo["eventid"] = this.state.eventListInfo.eventid;
          eventinfo["eventdate"] = this.state.eventListInfo.eventdate;
          eventinfo["eventenddate"] = this.state.eventListInfo.eventenddate;

          eventinfo["eventtype"] = this.state.eventListInfo.eventtype;
          eventinfo["customerinfo"] = this.state.eventListInfo.customerinfo[0];
          eventinfo["dedicationInfo"] = this.state.dedicationInfo;
          delete eventinfo["message"];
          addressinfo = JSON.stringify(eventinfo);
          //		  		console.log(addressinfo)

          //this.setState({shippingCost:0 })
          //shippingCost=0
        } else {
          var formData = parseFormData(
            this.state.formDataSD[this.state.activeFormSD].formData
          );
          if (this.state.haveDirection) {
            let pos = find(
              this.state.addressInfo,
              parseInt(formData.directions),
              "ADDRESSID"
            );
            if (pos >= 0) {
              let add = JSON.parse(this.state.addressInfo[pos].ADDRESSINFO);
              formData["addressinfo"] = add;
            }
          }
          if (formData["city"] === "") {
            let city = findValueById(
              this.state.formDataPD.city.values,
              this.state.formDataPD.city.value,
              "value",
              "text"
            );
            formData["city"] = city;
          }
          addressinfo = JSON.stringify(formData);
        }

        var _formDataPD = parseFormData(this.state.formDataPD);
        //var formDataI = parseFormData(this.state.formDataI)

        var coupons = this.state.discountSHMessages;
        coupons = coupons.concat(this.state.discountSTMessages);

        var city = findValueById(
          this.state.formDataPD.city.values,
          this.state.formDataPD.city.value,
          "value",
          "text"
        );

        //var _shippingCost = convertNumber(this.state.shippingCost)-convertNumber(this.state.discountShipping) <0?0:convertNumber(this.state.shippingCost)-convertNumber(this.state.discountShipping)
        var _discount =
          convertNumber(this.state.discount) +
          convertNumber(this.state.discountSubtotal);

        var _data = {
          storeid: this.state.storeid,
          paymentMethod: this.state.paymentMethod,
          statusid: this.state.statusid,
          shippingtypeid: this.state.shippingOptionId,
          shippingType: this.state.shippingType,
          shippingLabel: this.state.shippingLabel,
          subtotal: this.state.subtotal,
          shippingcost: shippingCost,
          discountShipping: this.state.discountShipping,
          paymnettype: this.state.paymentType,
          paymnettypeid: this.state.paymenttypeIdSelected,
          customerid: this.state.userId,
          city: city,
          addressinfo: addressinfo,
          invoiceid: 0,
          discount: _discount,
          cartsessionid: cartsessionid,
          products: products,
          coupons: coupons,
          dedicationInfo: this.state.dedicationInfo,
          exponeaId: this.state.exponeaId,
        };

        //console.log(_formDataPD)
        var data = { ..._data, ..._formDataPD };

        let resp = await callApi("frontend", "setOrdersCoupons", data);
        if (resp.success) {
          console.log("startorder", resp.subtotal)
          this.setState({
            subtotal: resp.subtotal,
          });

          var objData = {
            payment_type: this.state.paymentMethod,
            value: resp.subtotal,
            items: this.state.itemsGTM,
          };
          //console.log(objData);
          /*********BLOOMREACH*********/
          var objBloomreach_CheckoutPayment = {
            payment_method: this.state.paymentMethod,
            step_number: "2",
            step_title: "Payment Method Selection",
            origen: "web",
            total_price: resp.subtotal,
          };
          //console.log(objBloomreach_CheckoutPayment);
          window.exponea.track(
            "checkout_payment",
            objBloomreach_CheckoutPayment
          );
          /*********BLOOMREACH*********/

          setGTMEvent("add_payment_info", objData);

          var _validcouponcode = [];
          this.state.validcouponcode.map(function (key) {
            let pos = find(_validcouponcode, key.couponcode, "couponcode");
            if (pos < 0) {
              _validcouponcode.push({
                validcoupontempcode: resp.message,
                couponcode: key.couponcode,
              });
            }
          });

          this.setState({
            validcouponcode: _validcouponcode,
            tryout: 0,
            orderId: resp.message,
            addressForm: formData,
            tempcode: resp.message,
          });
          setCookie(
            process.env.REACT_APP_ORG_NAME + "_tempoUserCode",
            resp.message,
            0.16
          );
          var intervalId = setInterval(this.checkorder, 5000);
          this.setState({ intervalId: intervalId });
        } else {
          showError(toast, resp.message);
        }
      } else {
        showError(toast, getDefValues().noproductsCartError);
      }
    } catch (Excep) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", Excep);
    }
  };
  componentWillUnmount = () => {
    //	console.log("out of timer" )
    clearInterval(this.state.intervalId);
  };

  checkorder = async (event) => {
    if (this.state.tryout < 10) {
      //console.log("Checking", this.state.tryout)
      this.setState({ tryout: this.state.tryout + 1 });
      var login = getLoginInfo();
      var userId = 0;
      if (login.userId != null) {
        userId = login.userId;
      }
      let resp = await callApi("frontend", "checkOrderPaid", {
        orderid: this.state.orderId,
        userId: userId,
        languageid: getLanguage(),
      });
      if (resp.success) {
        if (resp.order[0].STATUSID > 1) {
          clearInterval(this.state.intervalId);
          this.props.history.push("/order/status/paid/" + this.state.orderId);
          window.location.reload();
        }
      }
    } else {
      clearInterval(this.state.intervalId);
    }
  };
  closeOrder = async (event) => {
    try {
      this.setState({ isLoading: true });
      let resp1 = await getStoresInfo();
      resp1 = this.state.shopCart;
      let cartsessionid = resp1.tmpSessionID;
      let formData = parseFormData(
        this.state.formDataSD[this.state.activeFormSD].formData
      );

      if (this.state.haveDirection) {
        let pos = find(
          this.state.addressInfo,
          parseInt(formData.directions),
          "ADDRESSID"
        );
        if (pos >= 0) {
          let add = JSON.parse(this.state.addressInfo[pos].ADDRESSINFO);
          formData["addressinfo"] = add;
        }
      }
      let addressinfo = JSON.stringify(formData);

      let _formDataPayment = parseFormData(this.state.formDataPayment);
      let dataPayment = JSON.stringify(_formDataPayment);

      var products = resp1.products;
      var city = findValueById(
        this.state.formDataPD.city.values,
        this.state.formDataPD.city.value,
        "value",
        "text"
      );

      var _formDataPD = parseFormData(this.state.formDataPD);

      //var _shippingCost = convertNumber(this.state.shippingCost)-convertNumber(this.state.discountShipping) <0?0:convertNumber(this.state.shippingCost)-convertNumber(this.state.discountShipping)
      var _discount =
        convertNumber(this.state.discount) +
        convertNumber(this.state.discountSubtotal);

      var _data = {
        storeid: this.state.storeid,
        paymentMethod: this.state.paymentMethod,
        orderid: this.state.orderId,
        statusid: this.state.statusid,
        shippingtypeid: this.state.shippingOptionId,
        shippingType: this.state.shippingType,
        subtotal: this.state.subtotal,
        shippingcost: this.state.shippingCost,
        discountShipping: this.state.discountShipping,
        paymnettype: this.state.paymentType,
        paymnettypeid: this.state.paymenttypeIdSelected,
        customerid: this.state.userId,
        shippingLabel: this.state.shippingLabel,
        city: city,
        addressinfo: addressinfo,
        dataPayment: dataPayment,
        invoiceid: 0,
        discount: _discount,
        cartsessionid: cartsessionid,
        products: products,
        validcouponcode: this.state.validcouponcode,
      };
      var data = { ..._data, ..._formDataPD };
      clearInterval(this.state.intervalId);
      let resp = await callApi("frontend", "updateStatusPending", data);

      //let resp = {success:true, message:"ok"}
      if (resp.success) {
        setCookie(
          process.env.REACT_APP_ORG_NAME + "_orderId",
          this.state.orderId,
          0.05
        );
        //yam

        setCookie(
          process.env.REACT_APP_ORG_NAME + "_genericCoupons",
          null,
          0.0001
        );

        this.props.history.push("/order/status/paidOffline");
        window.location.reload();
      } else {
        showError(toast, getDefValues().invalidOrderLabel);
        this.setState({ isLoading: false });
      }
    } catch (Excep) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", Excep);
    }
  };

  toggle = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });

    if (nr === 4 && !this.state.modalNumber) {
      this.setState({ showMap: false });
    }
  };

  toggleEventTerms = (event) => {
    this.setState({
      checkEventTerms: !this.state.checkEventTerms,
    });
  };

  todggleDiscount = (event) => {
    this.setState({
      showDiscount: !this.state.showDiscount,
    });
  };

  closeMap = (event) => {
    this.setState({ ...this.state, modal4: false, showMap: false });
    this.props.toggleListener();
  };

  hideMap = (event) => {
    this.setState({ showMap: false, showFormAdd: true });
    this.props.toggleListener();
  };

  toggleLogin = (nr) => () => {
    let show = "show" + nr;
    this.setState({
      [show]: !this.state[show],
    });
  };

  updateCart = async (event) => {
    this.setState({ isLoading: true });
    try {
      var values;
      if (event.target.name) {
        values = event.target.name.split("_");
      } else {
        values = event.target.parentNode.name.split("_");
      }
      var action = values[0];
      var productId = values[1];

      var data;
      var shopCartInfo = this.state.shopCartInfo;
      if (action === "quantity" || action === "update") {
        data = await updateCart(
          productId,
          action,
          toast,
          event.target.value,
          this.state.shopCartInfo
        );
      } else {
        data = await updateCart(productId, action, toast, event.target.value);
      }
      if (data) {
        shopCartInfo["products"] = data.products;
        this.props.updateCart(data);

        var subtotal = 0,
          subtotalLabel = 0,
          discountLabel = 0;
        var discount = data.discount;

        for (let k in data.products) {
          var key = data.products[k];
          if (key.NEWPRICED) {
            discount =
              discount -
              (key.price * key.quantity - key.NEWPRICED * key.quantity);
            subtotal = subtotal + key.subtotal;
            discountLabel =
              discountLabel +
              (key.price * key.quantity - key.NEWPRICED * key.quantity);
          } else {
            subtotal = subtotal + key.price * key.quantity;
          }
          subtotalLabel = subtotalLabel + key.price * key.quantity;
        }
        var combos = calculateSkusDiscount(data.products);
        //console.log("updatecart ", subtotal)
        this.setState({
          ...this.state,
          isLoading: false,
          products: data.products,
          combos: combos,
          shopCartInfo: shopCartInfo,
          subtotal: toFixed(subtotal),
          subtotalLabel: toFixed(subtotalLabel),
          discountLabel: discountLabel,
          discount: toFixed(discount),
        });

        if (!data.needCommit) {
          await this.checkCartProducts();
          await this.prevalOrderDiscount();
        }
      } else {
        this.setState({ ...this.state, isLoading: false });
      }
    } catch (Excep) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", Excep);
    }
  };

  responseFacebook = (response) => {
    //console.log(response);
  };

  callBackSN = async (res) => {
    try {
      this.setState({ isLoading: true });
      var status = await loginSN(res, toast, this.state.rememberme);
      if (status) {
        this.setState({ reloadPage: true });
        window.location.reload();
        //this.props.setLoginInfo(res)
      }
      this.setState({ isLoading: false });
    } catch (Excep) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", Excep);
    }
  };

  callBackSNError = (res) => {
    console.log("Login failed: res:", res);
  };

  render() {
    const MapLoader = withScriptjs(Map);

    return this.props.showSearcher ? (
      ""
    ) : (
      <>
        {this.state.reloadPage ? (
          <Redirect to={{ pathname: "/checkout/cart" }} />
        ) : (
          ""
        )}

        <PageLoading
          isLoading={this.state.isLoading}
          MetaTags={this.state.MetaTags}
        />

        <MDBModal
          isOpen={this.state.modal4}
          toggle={this.toggle(4)}
          fade
          centered
          className="fullheigth-modal"
        >
          <MDBModalHeader>
            <span className="h6-responsive">
              {" "}
              {getDefValues().chooseMapPoint}{" "}
            </span>
          </MDBModalHeader>
          <MDBModalBody className="popup-map">
            {this.state.showFormAdd ? (
              <Form
                formData={this.state.addressFormData}
                changeHandler={this.changeHandlerA}
                formConfig={this.state.addressFormConfig}
              />
            ) : (
              ""
            )}

            {this.state.showMap ? (
              <MapLoader
                coords={{ lat: this.state.latitude, lng: this.state.longitude }}
                height="380px"
                setMyLocation={this.setMyLocation}
                setLocation={this.setLocation}
                address={this.state.address}
                city={this.state.city}
                googleMapURL={
                  "https://maps.googleapis.com/maps/api/js?key=" +
                  GoogleMapsAPI +
                  "&libraries=places"
                }
                backLocation={this.hideMap}
                cancelLocation={this.closeMap}
                btncolor={this.state.btncolor}
                confirmLocation={this.confirmLocation}
                loadingElement={<div style={{ height: `380px` }}></div>}
              />
            ) : (
              ""
            )}
          </MDBModalBody>

          <div className="card">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 flex items-center">
                {this.state.showFormAdd ? (
                  <>
                    <button
                      className={
                        "btn btn-md btn-" +
                        this.state.btncolor +
                        " shipping-form-btn mr-1"
                      }
                      type="button"
                      onClick={this.saveTempLocation}
                    >
                      {getDefValues().selectLocationLabel}
                    </button>
                    <button
                      className={
                        "btn btn-md btn-outline-" +
                        this.state.btncolor +
                        " shipping-form-btn"
                      }
                      type="button"
                      onClick={this.closeMap}
                    >
                      {getDefValues().cancelLabel}
                    </button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </MDBModal>

        <MDBModal
          isOpen={this.state.modal1}
          toggle={this.toggle(1)}
          fade
          centered
          className="fullheigth-modal"
        >
          <MDBModalBody>
            <p className="flex items-left mt-3">
              <img
                src={getImagePath("/catalog/images/ico_login.svg")}
                alt="login"
              />
              <span className="h3-responsive">
                {" "}
                {getDefValues().loginLabel}{" "}
              </span>
            </p>

            {this.state.needLogin ? (
              <MDBAlert color="warning" className="">
                <p sytle={{ marginBottom: 5 }}>
                  <span className="h5-responsive">
                    {" "}
                    {getDefValues().needLoginMessage}{" "}
                  </span>
                </p>
              </MDBAlert>
            ) : (
              ""
            )}

            <div className="mb-20 mt-10"></div>

            <Login
              formData={this.state.formData}
              toggleF={this.toggleLogin("forgot")}
              toggleL={this.toggleLogin("login")}
              toggleR={this.toggleLogin("recovery")}
              changeHandler={this.changeHandlerL}
              needLogin={this.state.needLogin}
              login={this.login}
              guest={this.guest}
            />

            <div className="login-or mt-30">
              <span className="or-line"></span>
              <span className="span-or">{getDefValues().orLabel}</span>
            </div>

            <div className="text-muted flex items-center mt-3">
              <span>{getDefValues().loginWithSocialNetwork}</span>
            </div>

            <p className="mobile-col col-12 social-login text-center">
              <LoginG
                setExecuting={() => {
                  this.setState({ isLoading: !this.state.isLoading });
                }}
                stay={true}
              />
              {/*
              <GoogleLogin
                clientId={process.env.REACT_APP_API_KEY_GOOGLE}
                buttonText="Google"
                className="google"
                onSuccess={this.callBackSN}
                onFailure={this.callBackSNError}
                cookiePolicy={"single_host_origin"}
              />
              
					 <FacebookLogin
						 cssClass="ml-2 facebook"
						 appId={process.env.REACT_APP_API_KEY_LOGIN_FB}
						 autoLoad={false}
						 fields="name,email,picture,last_name,first_name"
						 scope="public_profile, email"
						 callback={this.callBackSN}
						 onFailure={this.callBackSNError}
						 textButton="Facebook"
						 icon="fa-facebook" />
					  */}
            </p>
          </MDBModalBody>
        </MDBModal>

        <MDBModal
          isOpen={this.state.modal3}
          toggle={this.toggle(3)}
          fade
          centered
          className="fullheigth-modal"
        >
          <MDBModalBody>
            <Form
              formData={this.state.formDataI}
              changeHandler={this.changeHandlerI}
              formConfig={{
                formlinetitle: "YES",
                colsize: "col-12",
                formtitle:
                  getDefValues().tryoutsCouponExceededTitle.toUpperCase(),
              }}
            />

            <div className="card">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 flex items-right">
                <button
                  className={
                    "btn btn-md btn-" +
                    this.state.btncolor +
                    " shipping-form-btn"
                  }
                  type="button"
                  onClick={this.confirmInvoce}
                >
                  {getDefValues().confirmInvoceDataLabel}
                </button>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>

        <MDBModal
          isOpen={this.state.modal3old}
          toggle={this.toggle(3)}
          fade
          centered
          className="fullheigth-modal"
        >
          <MDBModalBody>
            <Form
              formData={this.state.formDataI}
              changeHandler={this.changeHandlerI}
              formConfig={{
                formlinetitle: "YES",
                formtittleicon: getImagePath(
                  "/catalog/images/ico_shippment_step.svg"
                ),
                colsize: "col-12",
                formtitle: getDefValues().invoicetitleLabel.toUpperCase(),
              }}
            />

            <div className="card">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 flex items-right">
                <button
                  className={
                    "btn btn-md btn-" +
                    this.state.btncolor +
                    " shipping-form-btn"
                  }
                  type="button"
                  onClick={this.confirmInvoce}
                >
                  {getDefValues().confirmInvoceDataLabel}
                </button>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>

        <div className="page-wrapper" onClick={this.props.hideCart}>
          {this.state.showMap ? (
            ""
          ) : (
            <div className="content container-page custom-container container-fluid">
              <div className="row mt-2">
                <div className="col-12">
                  <div className="steps-form mb-3 mt-3">
                    <div className="steps-row steps-row-img">
                      <div className="steps-step steps-step-center">
                        <img
                          src={getImagePath(
                            "/catalog/images/ico_checkout_cart.svg"
                          )}
                          alt="shop-cart"
                          className={
                            this.props.history.location.hash !== "#formstep1"
                              ? "opcacity05"
                              : ""
                          }
                        />
                      </div>
                      <div className="steps-step steps-step-center">
                        <img
                          src={
                            this.state.eventListInfo
                              ? getImagePath(
                                  "/catalog/images/ico_login_step.svg"
                                )
                              : getImagePath(
                                  "/catalog/images/ico_shippment_step.svg"
                                )
                          }
                          alt="shop-cart"
                          className={
                            this.props.history.location.hash !== "#formstep1"
                              ? "opcacity05"
                              : ""
                          }
                        />
                      </div>
                      <div className="steps-step steps-step-center">
                        <img
                          src={getImagePath(
                            "/catalog/images/ico_credit_card.svg"
                          )}
                          alt="shop-cart"
                          className={
                            this.props.history.location.hash !== "#formstep3"
                              ? "opcacity05"
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="steps-form">
                    <div className="steps-row" style={{ display: "flex" }}>
                      <div
                        className="steps-step"
                        style={{ display: "inline-block", width: "33.333%" }}
                      >
                        <MDBBtn
                          onClick={this.changePanel1}
                          className={
                            "btn btn-" +
                            (this.props.history.location.hash === "#formstep1"
                              ? "primary"
                              : "light") +
                            " cbtn-circle"
                          }
                        >
                          <div
                            className={
                              "cbtn-circle-" +
                              (this.props.history.location.hash === "#formstep1"
                                ? "active"
                                : "inactive")
                            }
                          ></div>
                        </MDBBtn>

                        <p>{getDefValues().myCartLabel.toUpperCase()}</p>
                      </div>

                      <div
                        className="steps-step"
                        style={{ display: "inline-block", width: "33.333%" }}
                      >
                        <MDBBtn
                          style={{ cursor: "auto" }}
                          className={
                            "btn btn-" +
                            (this.props.history.location.hash === "#formstep3"
                              ? "primary"
                              : "light") +
                            " cbtn-circle"
                          }
                        >
                          <div
                            className={
                              "cbtn-circle-" +
                              (this.props.history.location.hash === "#formstep2"
                                ? "active"
                                : "inactive")
                            }
                          ></div>
                        </MDBBtn>

                        <p>
                          {this.state.eventListInfo
                            ? getDefValues().personalDataLabel.toUpperCase()
                            : getDefValues().ShippingLabel.toUpperCase()}
                        </p>
                      </div>

                      <div
                        className="steps-step"
                        style={{ display: "inline-block", width: "33.333%" }}
                      >
                        <MDBBtn
                          style={{ cursor: "auto" }}
                          className={
                            "btn btn-" +
                            (this.props.history.location.hash === "#formstep3"
                              ? "primary"
                              : "light") +
                            " cbtn-circle"
                          }
                        >
                          <div
                            className={
                              "cbtn-circle-" +
                              (this.props.history.location.hash === "#formstep3"
                                ? "active"
                                : "inactive")
                            }
                          ></div>
                        </MDBBtn>

                        <p>{getDefValues().paymentLabel.toUpperCase()} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.props.history.location.hash === "#formstep1" ? (
                <>
                  <CartInfo
                    changePanel={this.changePanel2}
                    validCoupon={this.validCoupon}
                    needReview={this.state.needReview}
                    lockCouponButton={this.state.lockCouponButton}
                    discountSHMessages={this.state.discountSHMessages}
                    discountSTMessages={this.state.discountSTMessages}
                    deleteCoupon={this.deleteCoupon}
                    discountShipping={this.state.discountShipping}
                    discountSubtotal={this.state.discountSubtotal}
                    isDisctountShipp={this.state.isDisctountShipp}
                    changeHandler={this.changeHandlerCartInfo}
                    couponcode={this.state.couponcode}
                    options={this.state.options}
                    resumeStyle={this.state.resumeStyle}
                    windowHeight={this.state.windowHeight}
                    products={this.state.products}
                    showDiscount={this.state.showDiscount}
                    todggleDiscount={this.todggleDiscount}
                    discount={this.state.discount}
                    subtotal={this.state.subtotal}
                    shippingCost={this.state.shippingCost}
                    saveDedicationForm={this.saveDedicationForm}
                    cancelDedicationForm={this.cancelDedicationForm}
                    subtotalLabel={this.state.subtotalLabel}
                    discountLabel={this.state.discountLabel}
                    btncolor={this.state.btncolor}
                    isLoading={this.state.isLoading}
                    eventListInfo={this.state.eventListInfo}
                    dedicationName={this.state.dedicationName}
                    dedicationMessage={this.state.dedicationMessage}
                    updateCart={this.updateCart}
                  />

                  {this.state.showDiscEvent ? (
                    <MDBAlert color="secondary" className="mt-2 mb-3">
                      <p className="p-fix">
                        <span className="h5-responsive">
                          {" "}
                          {getDefValues().disclaimerEventMessage}{" "}
                        </span>
                      </p>
                      <p className="p-fix">
                        <span className="h5-responsive">
                          {" "}
                          {getDefValues().disclaimerEventMessage2}{" "}
                        </span>
                      </p>
                    </MDBAlert>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}

              {this.props.history.location.hash === "#formstep2" ? (
                <div className="row mt-2">
                  <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                    <div className="shippingCard">
                      <Form
                        formData={this.state.formDataPD}
                        changeHandler={this.changeHandler}
                        formConfig={this.state.formConfigPD}
                        showPersonalData={this.state.showPersonalData}
                        lockform={this.state.lockformPD}
                        editForm={this.editPersonalData}
                      />

                      <div className="card">
                        {!this.state.lockformPD ? (
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 flex items-left middle">
                              {this.state.showDiscEvent && (
                                <label className="flex">
                                  <input
                                    className={"mr-1"}
                                    checked={this.state.checkEventTerms}
                                    value={"YES"}
                                    onChange={this.toggleEventTerms}
                                    type="checkbox"
                                  />{" "}
                                  {getDefValues().checktermsLabel}
                                </label>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 flex items-right middle">
                              {this.state.checkEventTerms ? (
                                <button
                                  className={
                                    "ml-2 btn btn-md btn-" +
                                    this.state.btncolor +
                                    " shipping-form-btn"
                                  }
                                  type="button"
                                  onClick={this.savePersonalData}
                                >
                                  {getDefValues().nextLabel}
                                </button>
                              ) : (
                                <button
                                  className={
                                    "ml-2 btn btn-md btn-" +
                                    this.state.btncolor +
                                    " shipping-form-btn"
                                  }
                                  type="button"
                                  disabled
                                >
                                  {getDefValues().nextLabel}
                                </button>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {this.state.showDiscEvent ? (
                      <MDBAlert color="secondary" className="mt-2 mb-3">
                        <p className="p-fix">
                          <span className="h4-responsive">
                            {" "}
                            {getDefValues().disclaimerEventMessage}{" "}
                          </span>
                        </p>
                        <p className="p-fix">
                          <span className="h4-responsive">
                            {" "}
                            {getDefValues().disclaimerEventMessage2}{" "}
                          </span>
                        </p>
                      </MDBAlert>
                    ) : (
                      ""
                    )}

                    {!this.state.eventListInfo ? (
                      <div className="shippingCard mt-3 mb-3">
                        <Form
                          formData={this.state.formDataSD}
                          changeHandler={this.changeHandler2}
                          activeForm={this.state.activeFormSD}
                          formConfig={{
                            formlinetitle: "YES",
                            btncolor: this.state.btncolor,
                            formtittleicon: getImagePath(
                              "/catalog/images/ico_shippment_step.svg"
                            ),
                            colsize: "col-12",
                            formtitle:
                              getDefValues().shippingInfoLabel.toUpperCase(),
                          }}
                          changeForm={this.changeForm}
                          lockform={this.state.lockformSD}
                          editForm={this.editShippingData}
                        />
                        <div className="card items-right">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 flex items-right">
                            {!this.state.lockformSD && this.state.isValidPD ? (
                              <button
                                className={
                                  "btn btn-md btn-" +
                                  this.state.btncolor +
                                  " shipping-form-btn"
                                }
                                type="button"
                                onClick={this.saveShippingData}
                              >
                                {getDefValues().nextLabel}
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="mb-3"></div>
                    )}
                  </div>

                  <div className=" col-sm-12 col-md-12 col-lg-3 col-xl-3 ">
                    <div style={this.state.resumeStyle}>
                      <div className="background-resume-order">
                        <div className="row">
                          <div className="col-12 resume-order-title">
                            <span className="h4-responsive">
                              {getDefValues().continueToPaymentLabel}
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-7">
                            <div>{getDefValues().subtotalOrderLabel}</div>
                          </div>
                          <div className="col-5 flex items-right  text-right">
                            <div>
                              <b>
                                {getCurrency()}
                                {this.state.subtotalLabel}
                              </b>
                            </div>
                          </div>
                        </div>

                        {this.state.shippingCost > 0 ? (
                          <>
                            <div className="row">
                              <div className="col-10 flex middle">
                                <div
                                  style={{
                                    lineHeight: "1",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {getDefValues().shippingCostLabel}
                                </div>
                              </div>
                              <div className="col-2 flex items-right text-right">
                                <div>
                                  {/*
															<span className="product-price-old">
															{this.state.discountShipping >0? getCurrency()+""+toFixed(this.state.shippingCost):""}
															</span>
															<b>{getCurrency()}{
																toFixed(parseFloat(this.state.shippingCost)-
																				parseFloat(this.state.discountShipping) <0?0:
																				parseFloat(this.state.shippingCost)-parseFloat(this.state.discountShipping))
																}</b>
															*/}
                                  <b>{toFixed(this.state.shippingCost)}</b>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        {this.state.discount > 0 ||
                        this.state.discountLabel > 0 ? (
                          <>
                            <div className="row">
                              <div className="col-7">
                                <div>
                                  <span
                                    onClick={this.todggleDiscount}
                                    className="text-underline text-bold pointer"
                                  >
                                    {" "}
                                    {getDefValues().discountLabel}
                                  </span>
                                  {!this.state.showDiscount ? (
                                    <i
                                      onClick={this.todggleDiscount}
                                      className="ml-1 font-sm pointer fi-rs-angle-down"
                                    />
                                  ) : (
                                    <i
                                      onClick={this.todggleDiscount}
                                      className="ml-1 font-sm pointer fi-rs-angle-up"
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="col-5 flex items-right">
                                <div>
                                  <b>
                                    {"-" + getCurrency()}
                                    {/*this.state.discount*/}
                                    {toFixed(
                                      parseFloat(this.state.discount) +
                                        this.state.discountLabel
                                    )}
                                  </b>
                                </div>
                              </div>
                            </div>

                            {!this.state.showDiscount ? (
                              ""
                            ) : (
                              <>
                                {this.state.combos.map((prod) => (
                                  <div className="row discount-mini-label">
                                    <div className="col-6">
                                      <div>
                                        <span className="text-bold">
                                          {prod.productname}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-6 flex items-right text-right">
                                      <div>
                                        <div className="discount-2-span">
                                          <span>
                                            {"-" +
                                              getCurrency() +
                                              toFixed(prod.discount)}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                {this.state.products.map((prod) =>
                                  prod.NEWPRICED ? (
                                    <div className="row discount-mini-label">
                                      <div className="col-6">
                                        <div>
                                          <span className="text-bold">
                                            {prod.productname}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-6 flex items-right text-right">
                                        <div>
                                          <div className="discount-2-span">
                                            <span>
                                              {
                                                /*prod.priceOld*/
                                                "-" +
                                                  getCurrency() +
                                                  toFixed(
                                                    prod.quantity * prod.price -
                                                      prod.quantity *
                                                        prod.NEWPRICED
                                                  )
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          ""
                        )}

                        {this.state.discountSTMessages.map((item) => (
                          <div className="row">
                            <div className="col-8 flex items-left text-left">
                              <div
                                style={{
                                  lineHeight: "1",
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "13px",
                                  color: "#d50028",
                                }}
                              >
                                {item.couponlabel}
                              </div>
                            </div>
                            <div className="col-4 flex items-right text-right">
                              <div>
                                <b>
                                  {"-" + getCurrency() + item.discountamount}
                                </b>
                              </div>
                            </div>
                          </div>
                        ))}

                        {this.state.discountSHMessages.length > 0 ? (
                          <div className="row">
                            <div className="col-8 flex items-left text-left">
                              <div
                                style={{
                                  lineHeight: "1",
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "13px",
                                  color: "#d50028",
                                }}
                              >
                                {this.state.discountSHMessages[0].couponlabel}
                              </div>
                            </div>
                            <div className="col-4 flex items-right text-right">
                              <div>
                                <b>
                                  {"-" +
                                    getCurrency() +
                                    (this.state.discountShipping >
                                    this.state.shippingCost
                                      ? toFixed(this.state.shippingCost)
                                      : toFixed(this.state.discountShipping))}
                                </b>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <span className="or-line"></span>
                        <div className="row mb-2 mt-3">
                          <div className="col-7">
                            {getDefValues().totalOrderLabel}
                          </div>
                          <div className="col-5 flex items-right text-right">
                            <b>
                              {getCurrency()}
                              {parseFloat(this.state.subtotal) -
                                parseFloat(this.state.discount) -
                                parseFloat(this.state.discountSubtotal) -
                                (this.state.isDisctountShipp
                                  ? parseFloat(this.state.discountShipping)
                                  : 0) <
                              0
                                ? toFixed(
                                    0 +
                                      (parseFloat(this.state.shippingCost) -
                                        parseFloat(
                                          this.state.discountShipping
                                        ) <
                                      0
                                        ? 0
                                        : parseFloat(this.state.shippingCost) -
                                          parseFloat(
                                            this.state.discountShipping
                                          ))
                                  )
                                : toFixed(
                                    parseFloat(this.state.subtotal) -
                                      parseFloat(this.state.discount) -
                                      parseFloat(this.state.discountSubtotal) -
                                      (this.state.isDisctountShipp
                                        ? parseFloat(
                                            this.state.discountShipping
                                          )
                                        : 0) +
                                      (parseFloat(this.state.shippingCost) -
                                        parseFloat(
                                          this.state.discountShipping
                                        ) <
                                      0
                                        ? 0
                                        : parseFloat(this.state.shippingCost) -
                                          parseFloat(
                                            this.state.discountShipping
                                          ))
                                  )}
                            </b>
                          </div>
                        </div>

                        {parseFloat(this.state.subtotal) -
                          parseFloat(this.state.discount) -
                          parseFloat(this.state.discountSubtotal) +
                          (this.state.isDisctountShipp
                            ? parseFloat(this.state.discountShipping)
                            : 0) <
                        0 ? (
                          <>
                            <span className="or-line"></span>
                            <div className="row">
                              <div className="col-7">
                                {getDefValues().positiveBalanceLabel}
                              </div>
                              <div className="col-5 flex items-right text-right">
                                <b>
                                  {getCurrency()}
                                  {toFixed(
                                    parseFloat(this.state.discount) +
                                      parseFloat(this.state.discountSubtotal) -
                                      parseFloat(this.state.subtotal) -
                                      (this.state.isDisctountShipp
                                        ? parseFloat(
                                            this.state.discountShipping
                                          )
                                        : 0)
                                  )}
                                </b>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        <span className="or-line mt-1 mb-3"></span>
                        <div className="row">
                          <div className="col-12 ">
                            {this.state.totalProducts +
                              " " +
                              getDefValues().productsLabel}
                          </div>
                        </div>
                        <div className="resume-order-mini">
                          {this.state.products.map((item) => (
                            <div className="row">
                              <div className="col-4">
                                <img
                                  alt={item.productname}
                                  src={getImagePath(item.image)}
                                  className="card-img-top"
                                />
                              </div>
                              <div className="col-8 flex middle">
                                <p>
                                  <div>
                                    {item.productname + " x " + item.quantity}
                                  </div>

                                  {/*item.NEWPRICED?
																	<span style={{paddingTop: 2}} className="discount-span"><span>{ item.priceOld }</span></span>
																:""	
																}
																{item.NEWPRICEC1?
																	<span style={{paddingTop: 2}} className="discount-span"><span>{  item.priceOld }</span></span>
																:""	
																*/}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-12">
                          {!this.state.isLoading &&
                          this.state.lockformSD &&
                          this.state.isValidPD &&
                          this.props.history.location.hash === "#formstep2" ? (
                            <button
                              disabled={
                                this.state.isValidPD && this.state.isValidSD
                                  ? false
                                  : true
                              }
                              onClick={this.saveShippingData}
                              className={
                                "btn btn-" +
                                this.state.btncolor +
                                " text-bold btn-block"
                              }
                            >
                              {getDefValues().continueToPaymentLabel.toUpperCase()}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12 mt-2 mb-2">
                          <Link to="/">
                            <span className="add-more-products-link">
                              {getDefValues().continueBuyingLabel.toUpperCase()}
                            </span>
                          </Link>
                        </div>
                      </div>

                      {parseFloat(this.state.subtotal) -
                        parseFloat(this.state.discount) -
                        parseFloat(this.state.discountSubtotal) +
                        (this.state.isDisctountShipp
                          ? parseFloat(this.state.discountShipping)
                          : 0) <
                      0 ? (
                        <div className="couponmessage mb-2">
                          <div className="row">
                            <div className="col-12">
                              <p>
                                <b>{getDefValues().oneUseOnlyCouponTitle}</b>
                              </p>
                              <p>
                                <span>
                                  {getDefValues().oneUseOnlyCouponLabel.replace(
                                    "<positiveBalance>",

                                    toFixed(
                                      parseFloat(this.state.discount) +
                                        parseFloat(
                                          this.state.discountSubtotal
                                        ) -
                                        parseFloat(this.state.subtotal) -
                                        (this.state.isDisctountShipp
                                          ? parseFloat(
                                              this.state.discountShipping
                                            )
                                          : 0)
                                    )
                                  )}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.props.history.location.hash === "#formstep3" ? (
                <>
                  <div className="row mt-3">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="row">
                        <div className="col-12">
                          <img
                            width="340"
                            height="120"
                            src={paymentsTitleIcon}
                            alt="payment_methotds"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <span className="h5-responsive">
                            {" "}
                            {getDefValues().choosePaymentMethodLabel}{" "}
                          </span>
                        </div>
                      </div>
                      {/*
										{",subtotal-"+this.state.subtotal}
										{",discount-"+this.state.discount}
										{",discountSubtotal-"+this.state.discountSubtotal}
										{",isDisctountShipp-"+this.state.isDisctountShipp}
										{",discountShipping-"+this.state.discountShipping+"-----:"}
										
										{parseFloat(this.state.subtotal) - parseFloat(this.state.discount) -parseFloat(this.state.discountSubtotal) + ( this.state.isDisctountShipp? parseFloat(this.state.discountShipping) :0 )}
										{"___.2._:"}
										{(this.state.subtotal) - (this.state.discount) -(this.state.discountSubtotal) + ( this.state.isDisctountShipp? (this.state.discountShipping) :0 )}
										*/}

                      {parseFloat(this.state.subtotal) -
                        parseFloat(this.state.discount) -
                        parseFloat(this.state.discountSubtotal) +
                        (this.state.isDisctountShipp
                          ? parseFloat(this.state.discountShipping)
                          : 0) <
                      0 ? (
                        <div className="row mt-3">
                          {this.state.paymenttypesFree.map((item) => (
                            <div className="col-12 mb-3 payment-type">
                              <label className="ml-2 h5-responsive">
                                <input
                                  value={item.PAYMENTTYPEID}
                                  className="mr-1 "
                                  type="radio"
                                  name="m1"
                                  checked={
                                    item.PAYMENTTYPEID ==
                                    this.state.paymenttypeIdSelected
                                      ? true
                                      : false
                                  }
                                  onClick={this.selectPaymentFree}
                                />
                                {item.PAYMENTNAME}
                                <span className="checkmark ml-1 ">
                                  {item.text}
                                </span>
                              </label>
                              {item.PAYMENTTYPEID ==
                                this.state.paymenttypeIdSelected &&
                              Object.keys(this.state.formDataPayment).length >
                                0 ? (
                                <>
                                  <div className="row">
                                    <div className="col-12">
                                      <Form
                                        formData={this.state.formDataPayment}
                                        changeHandler={this.changeHandler3}
                                        formConfig={{ colsize: "col-12" }}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              {item.PAYMENTTYPEID ==
                              this.state.paymenttypeIdSelected ? (
                                <div className="row">
                                  {this.state.paymentType === "WAIT" &&
                                  this.state.orderId != "0" ? (
                                    <div className="col-12 flex items-center mb-3">
                                      <button
                                        name="close"
                                        onClick={this.closeOrder}
                                        className={
                                          "btn btn-" +
                                          this.state.btncolor +
                                          " text-bold"
                                        }
                                      >
                                        {getDefValues().closeOrderLabel}
                                      </button>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="row mt-3">
                          {this.state.paymenttypes.map((item) => (
                            <div className="col-12 mb-3 payment-type">
                              <label className="ml-2 h5-responsive">
                                <input
                                  value={item.PAYMENTTYPEID}
                                  className="mr-1 "
                                  type="radio"
                                  name="m1"
                                  checked={
                                    item.PAYMENTTYPEID ==
                                    this.state.paymenttypeIdSelected
                                      ? true
                                      : false
                                  }
                                  onClick={this.selectPayment}
                                />
                                {item.PAYMENTNAME}
                                <span className="checkmark ml-1 ">
                                  {item.text}
                                </span>
                              </label>
                              {item.PAYMENTTYPEID ==
                                this.state.paymenttypeIdSelected &&
                              Object.keys(this.state.formDataPayment).length >
                                0 ? (
                                <>
                                  <div className="row">
                                    <div className="col-12">
                                      <Form
                                        formData={this.state.formDataPayment}
                                        changeHandler={this.changeHandler3}
                                        formConfig={{ colsize: "col-12" }}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              {item.PAYMENTTYPEID ==
                              this.state.paymenttypeIdSelected ? (
                                <div className="row">
                                  {this.state.paymentType === "WAIT" &&
                                  this.state.orderId != "0" ? (
                                    <div className="col-12 flex items-center mb-3">
                                      <button
                                        name="close"
                                        onClick={this.closeOrder}
                                        className={
                                          "btn btn-" +
                                          this.state.btncolor +
                                          " text-bold"
                                        }
                                      >
                                        {getDefValues().closeOrderLabel}
                                      </button>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {this.state.formActivePanel === 4 ? (
                <SuccesfulMessage
                  orderid={this.state.orderId}
                  toggle={this.toggle(2)}
                  city={this.state.city}
                  subtotal={this.state.subtotal}
                  formDataPD={this.state.formDataPD}
                  discount={this.state.discount}
                  shippingLabel={this.state.shippingLabel}
                  formDataSD={this.state.finalFormData}
                  shippingCost={this.state.shippingCost}
                  orderDetails={this.state.products}
                  paymentMethod={this.state.paymentMethod}
                  shippingType={this.state.shippingType}
                  addressForm={this.state.addressForm}
                  eventid={this.state.eventid}
                />
              ) : (
                ""
              )}

              {this.state.discountSTMessages.map((item) => (
                <div className="row">
                  <div className="col-12">
                    <MDBAlert color="success" className="mt-2 mb-2">
                      <p className="p-fix">
                        <span className="h5-responsive"> {item.message} </span>
                      </p>
                    </MDBAlert>
                  </div>
                </div>
              ))}

              {this.state.discountSHMessages.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <MDBAlert color="success" className="mt-2 mb-2">
                      <p className="p-fix">
                        <span className="h5-responsive">
                          {" "}
                          {this.state.discountSHMessages[0].message}{" "}
                        </span>
                      </p>
                    </MDBAlert>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

export default page;
